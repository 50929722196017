import { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import { IUserProps } from '../../../common/data/userDummyData';
import ZappiLogo from '../../../assets/logos/Zappi_Logo_Completo.jpeg';
import Checks from '../../../components/bootstrap/forms/Checks';
import showNotification from '../../../components/extras/showNotification';
import { getMe, loginUser } from '../../../services/authServices';
import UserImage from '../../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../../assets/img/wanna/wanna1.webp';
import SERVICES from '../../../common/data/serviceDummyData';
import ForgotPassword from './ForgotPassword';


interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenidos,</div>
			<div className='text-center h4 text-muted mb-5'>Inicia Sesión para continuar!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}


const Login: FC<ILoginProps> = ({ isSignUp }) => {

	const { setUser, setUserDataFromApi } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);


	const navigate = useNavigate();
	/* const handleOnClick = useCallback(() => navigate('/'), [navigate]); */


	// Modifica handleOnClick para redirigir según el rol del usuario
	const handleOnClick = useCallback((id: any) => {

		console.log('ROLLLLLLLLLLLLL IDDDDDD');

		navigate('/');

	}, [navigate]);


	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			checkOne: false,
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				const loginResponse = await loginUser(values.loginUsername, values.loginPassword);

				try {

					const meResponse = await getMe(loginResponse.access_token);

					const john: IUserProps = {
						id: `${meResponse.id}`,
						username: `${meResponse.email}`,
						name: `${meResponse.first_name}${meResponse.middle_name ? ' ' + meResponse.middle_name : ''}`,
						surname: `${meResponse.last_name} ${meResponse.mother_name}`,
						position: meResponse.role_id === 1 ? 'Administrador' : meResponse.role_id === 2 ? 'Director' : meResponse.role_id === 3 ? 'Profesor' : 'Padre',
						email: `${meResponse.email}`,
						rolId: meResponse.role_id,
						src: UserImage,
						srcSet: UserImageWebp,
						isOnline: true,
						isReply: true,
						color: 'primary',
						services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
						password: '@ABC123',
						token: loginResponse.access_token,
						institution: meResponse.institutions
					};
					if (meResponse) {
						localStorage.removeItem('institutionResponse');
						// Convertir institutionRespose a JSON
						const institutionResponseJSON = JSON.stringify(meResponse.institutions[0]);

						// Guardar en localStorage
						localStorage.setItem('institutionResponse', institutionResponseJSON);
					}
					if (setUser && setUserDataFromApi) {
						console.log('Llenando informacion');
						/* setUserDataFromApi(john); */
						localStorage.setItem('userFromApi', JSON.stringify(john));
						setUser('john'); // Establece el usuario en el contexto tras el inicio de sesión exitoso
						showNotification('Exito', 'El inicio de sesión ha sido exitoso.', 'success');
						handleOnClick(meResponse.role_id);
					}


				} catch (error) {
					if (error instanceof Error) {
						console.error('Error en la api get Me:', error.message);
					} else {
						console.error('Unexpected Error:', error);
					}
				}
				// Redirige a la página principal después del inicio de sesión exitoso
			} catch (error) {
				if (error instanceof Error) {
					showNotification('Credenciales Invalidas', 'El usario o contraseña son incorrectos.', 'danger');
					console.error('Error during login:', error.message);
				} else {
					console.error('Unexpected Error:', error);
				}
			}
		},
	});


	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className={classNames({ 'bg-light': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': true,
											},
										)}
										aria-label='Zappi'>
										<img src={ZappiLogo} alt="Zappi Logo" width={100} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-1 g-3 pb-1 px-1 '>
										<div className='text-center h2 fw-bold mt-3 mb-3'>Iniciar Sesión</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />
								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='loginUsername'
											isFloating
											label='Email o Identificación'
											className={classNames({
												'd-none': false,
											})}>
											<Input
												autoComplete='username'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={
													formik.errors.loginUsername
												}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>

										</FormGroup>
										<div className='mt-4'></div>
										<FormGroup
											id='loginPassword'
											isFloating
											label='Contraseña'
											className={classNames({
												'd-none': false,

											})}>

											<Input
												type={formik.values.checkOne ? 'text' : 'password'}
												id="loginPassword"
												name="loginPassword" // Opcional: asigna un nombre único si es necesario
												autoComplete="current-password"
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
											<div className='mt-3 px-2'>
												<Checks
													type='switch'
													id='inlineCheckOne'
													label='Mostrar contraseña'
													name='checkOne'
													onChange={formik.handleChange}
													checked={formik.values.checkOne}
												/>
											</div>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											className='w-100 py-3'
											onClick={formik.handleSubmit}>
											Iniciar
										</Button>
									</div>
									<div className='col-12 text-center'>
										<ForgotPassword />
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								className={classNames('text-decoration-none me-3')}>
								Privacy policy
							</a>
							<a
								className={classNames('text-decoration-none')}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
