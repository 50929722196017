import React, { useState } from 'react';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import showNotification from '../../../components/extras/showNotification';
import Modal, { ModalHeader, ModalBody, ModalFooter, ModalTitle } from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import axios from 'axios';
import { loginUser } from '../../../services/authServices'; // Import the loginUser API
import Icon from '../../../components/icon/Icon'; // Tu componente de íconos

const ForgotPassword = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1); // Step 1: Email, Step 2: Code, Step 3: Change password
  const [tempPassword, setTempPassword] = useState(''); // Temporary password (code)
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
  const [accessToken, setAccessToken] = useState(''); // Store the access token after login
  const apiBase = 'http://192.81.211.6/backend/v1';

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSendToken = async () => {
    if (!email) {
      showNotification('Error', 'Por favor, ingrese un correo electrónico.', 'danger');
      return;
    }
    try {
      const response = await axios.post(`${apiBase}/api/auth/send-token/${email}`);

      if (response.status === 200) {
        showNotification('Éxito', 'Correo enviado exitosamente. Verifique su correo.', 'success');
        setStep(2); // Avanza al paso de ingresar el código
      } else {
        showNotification('Error', 'Error al enviar el correo.', 'danger');
      }
    } catch (error) {
      showNotification('Error', 'Error al conectar con el servidor.', 'danger');
    }
  };

  const handleVerifyCode = async () => {
    if (!tempPassword) {
      showNotification('Error', 'Por favor, ingrese el código recibido.', 'danger');
      return;
    }

    try {
      // Verificación de la contraseña temporal con la API de login
      const response = await loginUser(email, tempPassword);

      if (response) {
        setAccessToken(response.access_token); // Guardamos el access_token para usarlo en la actualización de la contraseña
        showNotification('Éxito', 'Código verificado. Proceda a cambiar su contraseña.', 'success');
        setStep(3); // Avanza al paso de cambiar la contraseña
      } else {
        showNotification('Error', 'Código incorrecto.', 'danger');
      }
    } catch (error) {
      showNotification('Error', 'Error al verificar el código.', 'danger');
    }
  };

  const handleUpdatePassword = async () => {
    if (!newPassword || !confirmPassword) {
      showNotification('Error', 'Por favor, complete ambos campos de contraseña.', 'danger');
      return;
    }

    if (newPassword !== confirmPassword) {
      showNotification('Error', 'Las contraseñas no coinciden.', 'danger');
      return;
    }

    try {
      const response = await axios.put(`${apiBase}/api/auth/reset-password`, {
        username: email,
        password: newPassword,
        password_confirmation: confirmPassword,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}` // Usamos el token almacenado
        }
      });

      if (response.status === 200) {
        showNotification('Éxito', 'Contraseña actualizada exitosamente.', 'success');
        handleCloseModal(); // Cierra el modal después de actualizar la contraseña
      } else {
        showNotification('Error', 'Error al actualizar la contraseña.', 'danger');
      }
    } catch (error) {
      showNotification('Error', 'Error al conectar con el servidor.', 'danger');
    }
  };

  return (
    <>
      <Button className="text-danger fw-bold" color="link" onClick={handleOpenModal}>
        Olvidé contraseña
      </Button>

      {showModal && (
        <Modal isOpen={showModal} setIsOpen={setShowModal}>
          <ModalHeader setIsOpen={setShowModal}>
            <ModalTitle id="forgot-password-title">
              {step === 1
                ? 'Recuperar contraseña'
                : step === 2
                ? 'Verificar código'
                : 'Actualizar contraseña'}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            {step === 1 && (
              <>
                <p>
                  Por favor, ingrese su correo electrónico para recibir una contraseña temporal con la que
                  podrá acceder nuevamente a su cuenta.
                </p>
                <FormGroup id="email">
                  <label htmlFor="email">Correo electrónico</label>
                  <Input
                    type="email"
                    placeholder="ejemplo@correo.com"
                    value={email}
                    // @ts-ignore
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </>
            )}

            {step === 2 && (
              <>
                <p>
                  Ingrese el código que ha recibido en su correo electrónico (contraseña temporal).
                </p>
                <FormGroup id="tempPassword">
                  <label htmlFor="tempPassword">Código de verificación</label>
                  <Input
                    type="text"
                    placeholder="Código recibido"
                    value={tempPassword}
                    // @ts-ignore
                    onChange={(e) => setTempPassword(e.target.value)}
                  />
                </FormGroup>
              </>
            )}

            {step === 3 && (
              <>
                <p>Por favor, ingrese su nueva contraseña.</p>
                <FormGroup id="newPassword">
                  <label htmlFor="newPassword">Nueva contraseña</label>
                  <div className="position-relative">
                    <Input
                      type={showNewPassword ? 'text' : 'password'}
                      placeholder="Nueva contraseña"
                      value={newPassword}
                      // @ts-ignore
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <span
                      className="position-absolute end-0 top-50 translate-middle-y me-3"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon icon={showNewPassword ? 'Visibility' : 'VisibilityOff'} size="lg" />
                    </span>
                  </div>
                </FormGroup>
                <FormGroup id="confirmPassword">
                  <label htmlFor="confirmPassword">Confirmar contraseña</label>
                  <div className="position-relative">
                    <Input
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirmar contraseña"
                      value={confirmPassword}
                      // @ts-ignore
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span
                      className="position-absolute end-0 top-50 translate-middle-y me-3"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon icon={showNewPassword ? 'Visibility' : 'VisibilityOff'} size="lg" />
                    </span>
                  </div>
                </FormGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button
              className="ms-2"
              color="primary"
              onClick={
                step === 1
                  ? handleSendToken
                  : step === 2
                  ? handleVerifyCode
                  : handleUpdatePassword
              }
            >
              {step === 1
                ? 'Enviar'
                : step === 2
                ? 'Verificar código'
                : 'Actualizar contraseña'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ForgotPassword;
